// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url: 'http://192.168.0.163:3001/api', // API LocalHost
   url: 'https://api.dev.trialviu.com',
  // url: 'http://localhost:3002', // API LocalHost
  scoresBucket:'viuhealth-scores-us-east-1-dev',
  adobeClientID: '8c0cd670273d451cbc9b351b11d22318',
  folder: 'trialviu-medical-records-dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
